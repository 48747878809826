
import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import MultiStateDaySelector from '@/components/MultiStateDaySelector/MultiStateDaySelector.vue'
import PartouLoader from '@/components/PartouComponents/PartouLoader.vue'
import ChildBenefitCalculator from '@/components/ChildBenefitCalculator/ChildBenefitCalculator.vue'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import { NAMESPACES, STATE, GETTERS, ACTIONS } from '@/store'
import { namespace } from 'vuex-class'
import DayCheckboxState from '@/components/InputFields/DaySelector/DayCheckboxState'
import PartouFloatingCircleButton from '@/components/PartouComponents/Buttons/PartouFloatingCircleButton.vue'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import PartouIconButton from '@/components/PartouComponents/Buttons/PartouIconButton.vue'
import { Proposition, School, SchoolGroup, ServiceKind, ServiceVarietyName } from '@/models'
import { determineTypeOfCare } from '@/models/enums/ServiceKind'
import PropositionSubscription from '@/models/PropositionSubscription'
import SchoolSelectionForm from '@/components/AvailabilitySelector/SchoolSelectionForm.vue'
import BirthAndStartDateSelectionForm from '@/components/AvailabilitySelector/BirthAndStartDateSelectionForm.vue'
import ISchoolServiceService from '@/services/SchoolServiceService/ISchoolServiceService'
import container, { SERVICE_IDENTIFIERS } from '@/init/container'
import i18n from '@/plugins/i18n'
import DayCheckboxType from '../InputFields/DaySelector/DayCheckboxType'
import { SchoolValidationResponse } from '@/store/modules/userSelection/ValidatorEnums/SchoolValidationResponse'
import { eventBus } from '@/EventBus'

const userSelectionModule = namespace(NAMESPACES.userSelection)

@Component({
  components: {
    PartouButton,
    PartouIconButton,
    ChildBenefitCalculator,
    PartouFloatingCircleButton,
    MultiStateDaySelector,
    PartouLoader,
    SchoolSelectionForm,
    BirthAndStartDateSelectionForm
  }
})
export default class AvailabilitySelector extends Vue {
  @Ref('selectionGuideDatesForm')
  selectionGuideDatesForm! : HTMLFormElement

  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  @userSelectionModule.Action(ACTIONS.userSelection.setSelectedSchoolAsync)
  setSelectedSchoolAsync!: (selectedSchool: School | undefined) => Promise<void>

  @userSelectionModule.Action(ACTIONS.userSelection.setSelectedSchoolLocalityAsync)
  setSelectedSchoolLocalityAsync!: (selectedSchoolLocality: string) => Promise<void>

  @userSelectionModule.Action(ACTIONS.userSelection.setSelectedSchoolGroupAsync)
  setSelectedSchoolGroupAsync!: (selectedSchoolGroup: SchoolGroup | undefined) => Promise<void>

  @userSelectionModule.Getter(GETTERS.userSelection.getDayCheckboxState)
  getDayCheckboxState!: (withAvailability: boolean, withOpeningHours: boolean) => Record<ServiceVarietyName, DayCheckboxState[]>

  @userSelectionModule.Getter(GETTERS.userSelection.getCurrentSelectionStep)
  getCurrentSelectionStep!: (resetCurrentStepTo?: number) => number

  @Prop({ required: false })
  proposition? : Proposition

  @Prop({ required: false })
  serviceKind? : ServiceKind

  @Prop({ required: false })
  defaultPropositionSubscription? : PropositionSubscription

  @Prop({ required: false, default: () => [] })
  allPropositionSubscriptions?: PropositionSubscription[]

  @Prop({ required: true })
  isLoadingProposition! : boolean

  @Prop({ required: true })
  isLoadingPrice! : boolean

  @Prop({ required: false, default: false })
  displayActionButtonsBelow! : boolean

  @Prop({ required: false, default: false })
  isDaySelectionRequired! : boolean

  @Prop({ required: false, default: true })
  showCalculator? : boolean

  @Prop({ required: false, default: true })
  showBookButton? : boolean

  @Prop({ required: false, default: true })
  showResetButton? : boolean

  @Prop({ required: false, default: false })
  showActionButton! : boolean

  @Prop({ required: false, default: '$vuetify.icons.partouInfo' })
  actionButtonIcon! : string

  @Prop({ required: false, default: '' })
  actionButtonClass! : string

  @Prop({ required: false, default: '' })
  actionButtonAriaLabel!: string

  @Prop({ required: false, default: true })
  showImage! : boolean

  @Prop({ required: false, default: i18n.t('availabilitySelector.checkAvailability').toString() })
  stepThreeNextStepText?: string

  @Prop({ required: false, default: false })
  withPostalCode!: boolean

  @Prop({ required: false, default: false })
  limitWidth!: boolean

  @Prop({ required: false, default: false })
  isReset!: boolean

  @Prop({ required: false, default: false })
  showCheckboxesBasedOnServiceKind! : boolean

  @userSelectionModule.Getter(GETTERS.userSelection.getIsValidSchoolLocality)
  getIsValidSchoolLocality!: () => boolean

  @userSelectionModule.Getter(GETTERS.userSelection.getIsValidSchool)
  getIsValidSchool!: () => SchoolValidationResponse

  @userSelectionModule.Getter(GETTERS.userSelection.getIsValidSchoolGroup)
  getIsValidSchoolGroup!: () => boolean

  schoolServiceService!: ISchoolServiceService
  isValidatingPostalCode = false

  get isNextButtonDisabled () : boolean {
    return !this.getIsValidSchoolLocality() || this.getIsValidSchool() !== SchoolValidationResponse.Success || !this.getIsValidSchoolGroup()
  }

  get isPreorder () : boolean {
    return !!(this.proposition && this.proposition.isPreorder)
  }

  get offeredServiceVarieties () : Array<ServiceVarietyName> {
    return this.proposition?.offeredServiceVarieties ?? []
  }

  @Watch('serviceKind')
  onServiceKindChanged () : void {
    this.currentStep = this.getCurrentSelectionStep(this.isReset ? 0 : -1)
    if (this.onServiceKindChangedAction) {
      this.onServiceKindChangedAction()
      this.onServiceKindChangedAction = undefined
    }
  }

  @Watch('userSelectionFormState', { deep: true })
  async onUserSelectionFormStateChanged (): Promise<void> {
    if (this.isValidatingPostalCode && this.userSelectionFormState.postalCodeValidationState && !this.userSelectionFormState.postalCodeValidationState.pending) {
      await this.onDatesSelectionNextClicked()
    }
  }

  onServiceKindChangedAction?: () => void

  isValid = false
  showPostalCodeTooltip = false
  isValidatingForm = false
  showSchoolNotAvailableErrorMessage = false

  isValidDates = false
  isValidSchoolSelection = false
  isResetted = false
  currentStep = 0

  @Watch('isReset', { immediate: true })
  updateIsResetted () : void {
    this.isResetted = this.isReset
    this.currentStep = this.getCurrentSelectionStep(this.isResetted ? 0 : -1)
  }

  beforeCreate () : void {
    this.schoolServiceService = container.get<ISchoolServiceService>(SERVICE_IDENTIFIERS.ISchoolServiceService)
  }

  mounted () : void {
    this.currentStep = this.getCurrentSelectionStep(this.isResetted ? 0 : -1)
  }

  resetAvailabilitySelector () :void {
    this.isResetted = true
    this.currentStep = this.getCurrentSelectionStep(this.isResetted ? 0 : -1)
  }

  getCalculationStartDate () : Date | undefined {
    if (this.userSelectionFormState?.startDateOfDayCare instanceof Date) {
      return this.userSelectionFormState?.startDateOfDayCare
    }

    return undefined
  }

  getDayCheckboxstates () : Record<ServiceVarietyName, DayCheckboxState[]> {
    const serviceKind = this.getServiceKind()
    if (!serviceKind) {
      throw new Error('Servicekind should be set when getting daycheckbox states ')
    }

    return this.proposition ? this.getDayCheckboxState(true, true) : this.getDayCheckboxState(false, false)
  }

  getServiceKind () : ServiceKind | undefined {
    return this.proposition ? this.proposition.kind as ServiceKind : this.serviceKind
  }

  onIsValidSchoolSelectionChanged (isValid : { isValidSchool: boolean, isValidSchoolGroup: boolean, isSchoolSelected: boolean, isSchoolGroupSelected: boolean, numberOfSchoolGroups : number }) : void {
    this.isValidSchoolSelection = isValid.isValidSchool && (isValid.isSchoolGroupSelected || isValid.numberOfSchoolGroups === 0) && isValid.isSchoolSelected
  }

  isValidDatesUpdated (isValid: boolean) : void {
    this.isValidDates = isValid
  }

  validateSelectedDays () : boolean {
    const variety = this.getServiceKind() === ServiceKind.DayCare ? ServiceVarietyName.KDV : ServiceVarietyName.NSO
    const checkBoxStates = this.getDayCheckboxstates()
    return checkBoxStates[variety]?.some(d => d.isChecked && d.type !== DayCheckboxType.Closed) ?? false
  }

  async onDatesSelectionNextClicked () : Promise<void> {
    if (this.userSelectionFormState.postalCodeValidationState?.pending) {
      this.isValidatingPostalCode = true
      return
    }
    this.isValidatingPostalCode = false
    if ((this.withPostalCode && !this.userSelectionFormState.postalCodeValidationState?.isValid) || !this.isValidDates) {
      return
    }

    const validatedAge = this.validateAge()
    const requiredServiceKind = validatedAge.requiredServiceKind
    if (validatedAge.isValid && requiredServiceKind) {
      const validatedAge = this.validateAge()
      const requiredServiceKind = validatedAge.requiredServiceKind
      if (requiredServiceKind) {
        this.datesSelectionNextStep(requiredServiceKind)
      }
    } else {
      await this.selectionGuideDatesForm.validateFormAsync(true)
      // If there's a proposition, the correct age should be entered
      if (this.proposition && this.userSelectionFormState.dateOfBirth !== undefined && this.userSelectionFormState.startDateOfDayCare !== undefined) {
        this.onIncorrectAgeEntered()
        return
      }

      if (requiredServiceKind) {
        this.onCurrentServiceKindChanged(requiredServiceKind)
        // Set lazy execution of step-change to avoid rendering the wrong days first
        this.onServiceKindChangedAction = () => this.datesSelectionNextStep(requiredServiceKind)
      }
    }
    eventBus.$emit('validateIsBSOTransitionAge')
  }

  datesSelectionNextStep (serviceKind: ServiceKind) : void {
    if (serviceKind === ServiceKind.DayCare) {
      this.clearUserSelectedSchoolData()
      this.emitOnShowAvailabilityClicked()
      this.currentStep = this.getCurrentSelectionStep(this.isResetted ? 2 : -1)
      return
    }
    this.currentStep = this.getCurrentSelectionStep(this.isResetted ? 1 : -1)
  }

  clearUserSelectedSchoolData () : void {
    this.setSelectedSchoolAsync(undefined)
    this.setSelectedSchoolLocalityAsync('')
    this.setSelectedSchoolGroupAsync(undefined)
  }

  onShowAvailabilityClicked () : void {
    if (this.validateAge().isValid) {
      this.currentStep = this.getCurrentSelectionStep(this.isResetted ? 2 : -1)
      this.isResetted = false
      this.emitOnShowAvailabilityClicked()
    } else {
      this.onIncorrectAgeEntered()
    }
  }

  validateAge () : { currentServiceKind?: ServiceKind, requiredServiceKind?: ServiceKind, isValid: boolean } {
    const currentServiceKind = this.getServiceKind()
    if (!this.userSelectionFormState.dateOfBirth || !this.userSelectionFormState.startDateOfDayCare) {
      return { currentServiceKind, isValid: false }
    }

    const requiredServiceKind = determineTypeOfCare(this.userSelectionFormState.dateOfBirth, this.userSelectionFormState.startDateOfDayCare)
    return { currentServiceKind, requiredServiceKind, isValid: currentServiceKind === requiredServiceKind }
  }

  @Emit('onBackToLocationOverviewClicked')
  onBackToLocationOverviewClicked (useOtherServiceKind: boolean) : boolean {
    return useOtherServiceKind
  }

  @Emit('onStartBookingProcess')
  onStartBookingProcess () : void {
    // emits onStartBookingProcess event
  }

  @Emit('onIncorrectAgeEntered')
  onIncorrectAgeEntered () : void {
    // emits onIncorrectAgeEntered event
  }

  @Emit('onActionClicked')
  onActionClicked () : void {
    // emits onActionClicked event
  }

  @Emit('onDaySelectorChanged')
  onDaySelectorChanged (days: Record<ServiceVarietyName, DayCheckboxState[]>) : Record<ServiceVarietyName, DayCheckboxState[]> {
    return days
  }

  @Emit('onShowAvailabilityClicked')
  emitOnShowAvailabilityClicked () : void {
    // emits onShowAvailabilityClicked event
  }

  @Emit('onServiceKindChanged')
  onCurrentServiceKindChanged (serviceKind: ServiceKind) : ServiceKind {
    return serviceKind
  }
}
